table {
  & .snug {
    width: 1px;
    white-space: nowrap;
    text-align: center;
  }
}

.ck-content {
  & h1 {
    font-size: 2em;
    margin: 0.5em 0;
  }

  & h2 {
    font-size: 1.5em;
    margin: 0.3em 0;
  }

  & ul, & ol {
    margin: 0.5em 0 0.5em 2em;
  }

  & ul {
    list-style-type: disc;
  }

  & span[style] strong {
    color: inherit;
  }
}
