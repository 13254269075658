table .snug {
  width: 1px;
  white-space: nowrap;
  text-align: center;
}

.ck-content h1 {
  font-size: 2em;
  margin: 0.5em 0;
}
.ck-content h2 {
  font-size: 1.5em;
  margin: 0.3em 0;
}
.ck-content ul, .ck-content ol {
  margin: 0.5em 0 0.5em 2em;
}
.ck-content ul {
  list-style-type: disc;
}
.ck-content span[style] strong {
  color: inherit;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3JhaWxzL2FwcC92aWV3cy9sYXlvdXRzL2NzcyIsInNvdXJjZXMiOlsiaW5kZXguc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRTtFQUNFO0VBQ0E7RUFDQTs7O0FBS0Y7RUFDRTtFQUNBOztBQUdGO0VBQ0U7RUFDQTs7QUFHRjtFQUNFOztBQUdGO0VBQ0U7O0FBR0Y7RUFDRSIsInNvdXJjZXNDb250ZW50IjpbInRhYmxlIHtcbiAgJiAuc251ZyB7XG4gICAgd2lkdGg6IDFweDtcbiAgICB3aGl0ZS1zcGFjZTogbm93cmFwO1xuICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgfVxufVxuXG4uY2stY29udGVudCB7XG4gICYgaDEge1xuICAgIGZvbnQtc2l6ZTogMmVtO1xuICAgIG1hcmdpbjogMC41ZW0gMDtcbiAgfVxuXG4gICYgaDIge1xuICAgIGZvbnQtc2l6ZTogMS41ZW07XG4gICAgbWFyZ2luOiAwLjNlbSAwO1xuICB9XG5cbiAgJiB1bCwgJiBvbCB7XG4gICAgbWFyZ2luOiAwLjVlbSAwIDAuNWVtIDJlbTtcbiAgfVxuXG4gICYgdWwge1xuICAgIGxpc3Qtc3R5bGUtdHlwZTogZGlzYztcbiAgfVxuXG4gICYgc3BhbltzdHlsZV0gc3Ryb25nIHtcbiAgICBjb2xvcjogaW5oZXJpdDtcbiAgfVxufVxuIl19 */